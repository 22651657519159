export default [
  { name: "C++", icon: require("../../icons/c++-icon.svg"), alt: "C++ Icon" },
  { name: "Python", icon: require("../../icons/python-icon.svg"), alt: "Python Icon" },
  { name: "Java", icon: require("../../icons/java-icon.svg"), alt: "Java Icon" },
  { name: "JavaScript", icon: require("../../icons/javascript-icon.svg"), alt: "JavaScript Icon" },
  { name: "HTML5", icon: require("../../icons/html-5-icon.svg"), alt: "HTML5 Icon" },
  { name: "CSS3", icon: require("../../icons/css-icon.png"), alt: "CSS Icon" },
  { name: "ReactJS", icon: require("../../icons/react-icon.svg"), alt: "ReactJS Icon" },
  { name: "NodeJS", icon: require("../../icons/node-js-icon.svg"), alt: "NodeJS Icon" },
  { name: "Spring Boot", icon: require("../../icons/spring-logo-icon.svg"), alt: "Spring Boot Icon" },
  { name: "MongoDB", icon: require("../../icons/mongodb-icon.svg"), alt: "MongoDB Icon" },
  { name: "PostgreSQL", icon: require("../../icons/postgresql-icon.svg"), alt: "PostgreSQL Icon" },
  { name: "MySQL", icon: require("../../icons/mysql-icon.svg"), alt: "MySQL Icon" },
  { name: "Linux", icon: require("../../icons/linux-icon.svg"), alt: "Linux Icon" },
  { name: "Windows", icon: require("../../icons/windows-icon.svg"), alt: "Windows Icon" },
  { name: "IntelliJ", icon: require("../../icons/intellij-icon.svg"), alt: "IntelliJ Icon" },
  { name: "VS Code", icon: require("../../icons/visual-studio-code-icon.svg"), alt: "VsCode Icon" },
  { name: "Android Studio", icon: require("../../icons/android-studio-icon.svg"), alt: "IntelliJ Icon" },
  { name: "Git", icon: require("../../icons/github-icon.svg"), alt: "Github Icon" },
  { name: "Docker", icon: require("../../icons/docker-icon.svg"), alt: "Docker Icon" },
];
